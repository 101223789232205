import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormControl, Image, Row, Tab, Tabs } from "react-bootstrap";
import { ENurseryDataFields } from "../../models/cmsModels";
import { getCheckBoxField } from "../../helpers/uiHelper";

const Nursery = ({ register}) => {

    return (
        <Row >
            <h3>Nursery</h3>
            <div className="panel panel-default">
                <div className="panel-body">
                <Col sm={9}>
                    <br></br>
                    <Row>
                        <Form.Label>States where services provided</Form.Label>
                        {getCheckBoxField(register, ENurseryDataFields.nurseryServiceInNT, 'NT')}
                        {getCheckBoxField(register, ENurseryDataFields.nurseryServiceInWA, 'WA')}
                        {getCheckBoxField(register, ENurseryDataFields.nurseryServiceInSA, 'SA')}
                        {getCheckBoxField(register, ENurseryDataFields.nurseryServiceInVIC, 'VIC')}
                        {getCheckBoxField(register, ENurseryDataFields.nurseryServiceInTAS, 'TAS')}
                        {getCheckBoxField(register, ENurseryDataFields.nurseryServiceInACT, 'ACT')}
                        {getCheckBoxField(register, ENurseryDataFields.nurseryServiceInNSW, 'NSW')}
                        {getCheckBoxField(register, ENurseryDataFields.nurseryServiceInQLD, 'QLD')}
                        <br></br>
                        <Form.Group controlId="formUserNurseryCapaciy">
                            <Form.Label>Nursery Capacity</Form.Label>
                            <FormControl type="text" name="nurseryCapacity" placeholder="What is the total plant capacity of the nursery ? (eg. 5000 plants)" {...register('nurseryCapacity')} />
                        </Form.Group>
                    </Row>
                </Col>
                </div>
            </div>
        </Row>
    )
}

export default Nursery;