import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormControl, Image, Row, Tab, Tabs } from "react-bootstrap";
import { ESeedTestingDataFields } from "../../models/cmsModels";
import { getCheckBoxField } from "../../helpers/uiHelper";

const SeedTesting = ({register}) => {

    return (
        <Row >
            <h3>Seed Testing</h3>
            <div className="panel panel-default">
                <div className="panel-body">
                <Col sm={9}>
                    <br></br>
                    <Row>
                        <Form.Label>States where services provided</Form.Label>
                        {getCheckBoxField(register, ESeedTestingDataFields.seedTestingNT, 'NT')}
                        {getCheckBoxField(register, ESeedTestingDataFields.seedTestingWA, 'WA')}
                        {getCheckBoxField(register, ESeedTestingDataFields.seedTestingSA, 'SA')}
                        {getCheckBoxField(register, ESeedTestingDataFields.seedTestingVIC, 'VIC')}
                        {getCheckBoxField(register, ESeedTestingDataFields.seedTestingTAS, 'TAS')}
                        {getCheckBoxField(register, ESeedTestingDataFields.seedTestingACT, 'ACT')}
                        {getCheckBoxField(register, ESeedTestingDataFields.seedTestingNSW, 'NSW')}
                        {getCheckBoxField(register, ESeedTestingDataFields.seedTestingQLD, 'QLD')}
                    </Row>
                </Col>
                </div>
            </div>
        </Row>
    )
}

export default SeedTesting;