import React from "react";
import { Col, Form, Row } from "react-bootstrap";

import { getCheckBoxField } from "../../helpers/uiHelper";
import { ELandscapeRestorationDataFields } from "../../models/cmsModels";

const LandscapeRestoration = ({register}) => {

    return (
        <Row >
            <h3>Landscape Restoration</h3>
            <div className="panel panel-default">
                <div className="panel-body">
                <Col sm={9}>
                    <br></br>
                    <Row>
                        <Form.Label>States where services provided</Form.Label>
                        {getCheckBoxField(register, ELandscapeRestorationDataFields.landscapeRestorationNT, 'NT')}
                        {getCheckBoxField(register, ELandscapeRestorationDataFields.landscapeRestorationWA, 'WA')}
                        {getCheckBoxField(register, ELandscapeRestorationDataFields.landscapeRestorationSA, 'SA')}
                        {getCheckBoxField(register, ELandscapeRestorationDataFields.landscapeRestorationVIC, 'VIC')}
                        {getCheckBoxField(register, ELandscapeRestorationDataFields.landscapeRestorationTAS, 'TAS')}
                        {getCheckBoxField(register, ELandscapeRestorationDataFields.landscapeRestorationACT, 'ACT')}
                        {getCheckBoxField(register, ELandscapeRestorationDataFields.landscapeRestorationNSW, 'NSW')}
                        {getCheckBoxField(register, ELandscapeRestorationDataFields.landscapeRestorationQLD, 'QLD')}
                    </Row>
                </Col>
                </div>
            </div>
        </Row>
    )
}

export default LandscapeRestoration;