import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormControl, Image, Row, Tab, Tabs } from "react-bootstrap";
import { ESeedCollectorDataFields } from "../../models/cmsModels";
import { getCheckBoxField } from "../../helpers/uiHelper";

const SeedCollector = ({register}) => {

    return (
        <Row >
            <h3>Seed Collection</h3>
            <div className="panel panel-default">
                <h5>Specializations / Strata's</h5>
                <div className="panel-body">

                    {getCheckBoxField(register, ESeedCollectorDataFields.seedCollectorCanopyTree, 'Canopy / trees')}
                    {getCheckBoxField(register, ESeedCollectorDataFields.seedCollectorGroundLayer, 'Ground Layer / Graces / Herbs')}
                    {getCheckBoxField(register, ESeedCollectorDataFields.seedCollectorSeedMidStory, 'Seed Mid Story/ Shrub')}
                    {getCheckBoxField(register, ESeedCollectorDataFields.seedCollectorWetLand, 'Wet land species')}
                    {getCheckBoxField(register, ESeedCollectorDataFields.seedCollectorOther, 'Other')}

                </div>
                <div className="row justify-content-end">
                    <div className="col-4">
                        Select as many as required
                    </div>
                </div>
                
                <br></br>
                <h5>Collection Area(s) and Time(s)</h5>
                <p>Describe approximate collection areas (distance from nearest town) and the time of year collection usually occurs.</p>

                <Form.Group controlId="formLocation">
                    <Form.Label>Location</Form.Label>
                    <FormControl type="text" name="location" placeholder="Location" {...register(ESeedCollectorDataFields.seedCollectorLocation)} />
                </Form.Group>
                <Form.Group controlId="formDistance">
                    <Form.Label>Distance</Form.Label>
                    <FormControl type="text" name="distance" placeholder="Distance (km)" {...register(ESeedCollectorDataFields.seedCollectorDistance)} />
                </Form.Group>
                <Form.Group controlId="formMonths">
                    <Form.Label>Months</Form.Label>
                    <FormControl type="text" name="months" placeholder="Months of the year you actively collect seeds (eg. May till July)" {...register(ESeedCollectorDataFields.seedCollectorMonths)} />
                </Form.Group>
                <Col sm={9}>
                    <br></br>
                    <Row>
                        {getCheckBoxField(register, ESeedCollectorDataFields.seedCollectorSpecialistCleaning, 'Specialist Cleaning Equipment?')}
                        {getCheckBoxField(register, ESeedCollectorDataFields.seedCollectorSpecialistStorage, 'Specialist Storage Equipment?')}
                        <br></br>
                        <Form.Group>
                            <Form.Label>Further description of services/areas of expertise?</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="e.g. type your description here" {...register(ESeedCollectorDataFields.seedCollectorServiceExpertiseDescription)} />
                        </Form.Group>
                        <br></br>
                    </Row>
                </Col>
            </div>
        </Row>
    )
}

export default SeedCollector;