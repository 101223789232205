import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "gatsby";
import { Col, Container, Form, FormControl, Row, Tab, Tabs } from "react-bootstrap";
import LoadingOverlay from 'react-loading-overlay';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import "../assets/css/main.scss";
import { IOrgProfileSeedInterests, 
    IOrgSeedInterestData, IOrganisation, IOrgSubmitData } from "../models/cmsModels";
import { EFieldName, EOrgProfileSeedInterests} from "../constants/enums";
import { AddOrgRecord, UpdateUserRecord, cmsCollections, getRecordsByParam } from "../services/cmsService";
import SeedCollector from '../components/OrgProfile/OrgSeedInterest-SeedCollector';
import SeedProductionArea from "../components/OrgProfile/OrgSeedInterest-SeedProductionArea";
import ResearchOrganisation from "../components/OrgProfile/OrgSeedInterest-ResearchOrganisation";
import Nursery from "../components/OrgProfile/OrgSeedInterest-Nursery";
import LandscapeRestoration from "../components/OrgProfile/OrgSeedInterest-LandscapeRestoration";
import SeedBank from "../components/OrgProfile/OrgSeedInterest-SeedBank";
import SeedTesting from "../components/OrgProfile/OrgSeedInterest-SeedTesting";
import OtherService from "../components/OrgProfile/OrgSeedInterest-OtherService";
import { getIsUserAuthenticated, getToken, getUser, setUserProfile } from "../services/auth";
import Layout from "../components/Layout";
import { global, loaderStyle, waitingText } from '../constants/common';
import ToastContainer, { EMessageModeEnum } from "../components/shared/Toast/ToastContainer";
import { loginRequest } from "../msal/authConfig";

const NewOrg = () => {

    const isAuthenticated = getIsUserAuthenticated();

    const [ isLoading, setIsLoading ] = useState(false);
    const [currentUserId, setCurrentUserId] = useState(undefined);
    const [currentOrgId, setOrgId] = useState(undefined);
    const { register, handleSubmit } = useForm();
    const [key, setKey] = useState('contactInfo');
    const [message, setMessage] = useState('');
    const [ messageMode, setMessageMode ] = useState(EMessageModeEnum.SUCCESS);
    

    const [seedInterestFlags, setSeedInterestFlags] = useState({} as IOrgProfileSeedInterests);

    const token = getToken();
    const { instance } = useMsal();

    useEffect(() => {
        const fn = async () => {
            const token = getToken();

            const user = getUser();
            setIsLoading(true);
            const results = await getRecordsByParam(token, cmsCollections.Userprofiles, "email", user.email);
            setIsLoading(false);
            const userId = results?.userprofiles.length && results?.userprofiles[0].id;
            setCurrentUserId(userId);
            
            if (!token) {
                instance.loginRedirect(loginRequest);
            }
        };
    
        fn();
    }, [isAuthenticated]);

    let timeout: any;

    const showMessage = (message: string, mode: EMessageModeEnum) => {
        setMessage(message);
        setMessageMode(mode);
    };

    const toggleSeedInterestFlag = (name: string) => {
        const val = seedInterestFlags[name]
        setSeedInterestFlags({ ...seedInterestFlags, [name] : !val});
    };

    const getCheckBoxField = (seedInterestField: string) => {
        return (
            <Form.Group controlId={seedInterestField}>
                <Form.Check type="checkbox" 
                    onClick={() => toggleSeedInterestFlag(seedInterestField)} 
                    {...register(seedInterestField)} name={seedInterestField} label={EFieldName[seedInterestField]} />
            </Form.Group>
        )
    }

    const onSubmit = async (data) => {
        const payload : IOrganisation = {...data, "organisationOwnerId": currentUserId.toString()};
        const result  = await AddOrgRecord(token, cmsCollections.Organisations, payload);

        // console.log("jm: result from adding org ", result);

        // set the orgId
        setOrgId(result.data.id);

        // update user record - user wanted to create a new org ; set the user's org to the one that just got created
        const updateUserProfilePayload = {
            "id": currentUserId.toString(), 
            "organisation": result.data.orgName,
            "organisationId": result.data.id.toString(),
            "isOrgManager": true,
        }
        const result_updateUser = await UpdateUserRecord(token, cmsCollections.Userprofiles, updateUserProfilePayload);
        setUserProfile(result_updateUser.data);
        
        showMessage('New organisation was added!', EMessageModeEnum.SUCCESS);

        if (result_updateUser.statusText === "OK") {
            // the user is contactInfo tab, and hits update, then take user to seedInterests
            setKey("organisationDetails");
        }
    };

    const onSubmitOrgDetails = async(data2: IOrgSubmitData) => {
        if(currentOrgId) {

            const seedCollectorData = {
                seedCollectorCanopyTree: data2.seedCollectorCanopyTree,
                seedCollectorGroundLayer: data2.seedCollectorGroundLayer,
                seedCollectorOther: data2.seedCollectorOther,
                seedCollectorSeedMidStory: data2.seedCollectorSeedMidStory,
                seedCollectorWetLand: data2.seedCollectorWetLand,
                seedCollectorLocation: data2.seedCollectorLocation,
                seedCollectorDistance: data2.seedCollectorDistance,
                seedCollectorMonths: data2.seedCollectorMonths,
                seedCollectorSpecialistCleaning: data2.seedCollectorSpecialistCleaning,
                seedCollectorSpecialistStorage: data2.seedCollectorSpecialistStorage,
                seedCollectorServiceExpertiseDescription: data2.seedCollectorServiceExpertiseDescription
            }
    
            const seedProductionAreaData = {
                seedProductionAreaCanopyTree: data2.seedProductionAreaCanopyTree,
                seedProductionAreaGroundLayer: data2.seedProductionAreaGroundLayer,
                seedProductionAreaSeedMidStory: data2.seedProductionAreaSeedMidStory,
                seedProductionAreaWetLand: data2.seedProductionAreaWetLand,
                seedProductionAreaOther: data2.seedProductionAreaOther,
                seedProductionAreaLocation: data2.seedProductionAreaLocation,
                seedProductionAreaDistance: data2.seedProductionAreaDistance,
                seedProductionAreaMonths: data2.seedProductionAreaMonths,
                seedProductionAreaSpecialistCleaning: data2.seedProductionAreaSpecialistCleaning,
                seedProductionAreaSpecialistStorage: data2.seedProductionAreaSpecialistStorage,
                seedProductionAreaServiceExpertiseDescription: data2.seedProductionAreaServiceExpertiseDescription,
            }
    
            const researchOrganisationData = {
                researchOrganisationDescription: data2.ResearchOrgDescription,
            }
    
            const nurseryData = {
                nurseryServiceInNT: data2.nurseryServiceInNT,
                nurseryServiceInWA: data2.nurseryServiceInWA,
                nurseryServiceInSA: data2.nurseryServiceInSA,
                nurseryServiceInVIC: data2.nurseryServiceInVIC,
                nurseryServiceInTAS: data2.nurseryServiceInTAS,
                nurseryServiceInACT: data2.nurseryServiceInACT,
                nurseryServiceInNSW: data2.nurseryServiceInNSW,
                nurseryServiceInQLD: data2.nurseryServiceInQLD,
                nurseryCapacity: data2.nurseryCapacity,
            }
    
            const landscapeRestorationData = {
                landscapeRestorationNT: data2.landscapeRestorationNT,
                landscapeRestorationWA: data2.landscapeRestorationWA,
                landscapeRestorationSA: data2.landscapeRestorationSA,
                landscapeRestorationVIC: data2.landscapeRestorationVIC,
                landscapeRestorationTAS: data2.landscapeRestorationTAS,
                landscapeRestorationACT: data2.landscapeRestorationACT,
                landscapeRestorationNSW: data2.landscapeRestorationNSW,
                landscapeRestorationQLD: data2.landscapeRestorationQLD,
            }
    
            const seedBankData = {
                seedBankNT: data2.seedBankNT,
                seedBankWA: data2.seedBankWA,
                seedBankSA: data2.seedBankSA,
                seedBankVIC: data2.seedBankVIC,
                seedBankTAS: data2.seedBankTAS,
                seedBankACT: data2.seedBankACT,
                seedBankNSW: data2.seedBankNSW,
                seedBankQLD: data2.seedBankQLD,
                seedBankDescription: data2.seedBankDescription,
            }
    
            const seedTestingData = {
                seedTestingNT: data2.seedTestingNT,
                seedTestingWA: data2.seedTestingWA,
                seedTestingSA: data2.seedTestingSA,
                seedTestingVIC: data2.seedTestingVIC,
                seedTestingTAS: data2.seedTestingTAS,
                seedTestingACT: data2.seedTestingACT,
                seedTestingNSW: data2.seedTestingNSW,
                seedTestingQLD: data2.seedTestingQLD,
            }
    
            const otherServiceData = {
                otherServiceNT: data2.otherServiceNT,
                otherServiceWA: data2.otherServiceWA,
                otherServiceSA: data2.otherServiceSA,
                otherServiceVIC: data2.otherServiceVIC,
                otherServiceTAS: data2.otherServiceTAS,
                otherServiceACT: data2.otherServiceACT,
                otherServiceNSW: data2.otherServiceNSW,
                otherServiceQLD: data2.otherServiceQLD,
                otherServiceDescription: data2.otherServiceDescription,
            }
    
            const orgData : IOrgSeedInterestData = {
                seedCollectorData: [seedCollectorData],
                seedProductionAreaData: [seedProductionAreaData],
                researchOrganisationData: [researchOrganisationData],
                nurseryData: [nurseryData],
                landscapeRestorationData: [landscapeRestorationData],
                seedBankData: [seedBankData],
                seedTestingData: [seedTestingData],
                otherServiceData: [otherServiceData]
            }
    
            const payload : IOrganisation = {
                id: currentOrgId, 
                orgData: orgData,
                ...data2
            }
            UpdateUserRecord(token, cmsCollections.Organisations, payload);    
            
            showMessage("Updated Organisation Seed Interests!", EMessageModeEnum.SUCCESS);
        } else {
            // show when first tab not filled
            showMessage("Please finish filling the Contact Info in the 1st Tab", EMessageModeEnum.ERROR);
        }
    }

  return (
    <Layout>
    <ToastContainer message={message} mode={messageMode} />
    {
        <Container className="fl-page">
            <h3>New Organisation</h3>
            <LoadingOverlay active={isLoading} spinner text={waitingText} styles={loaderStyle}>
                <Tabs defaultActiveKey="contactInfo" id="manage-organisation" activeKey={key} onSelect={(k) => setKey(k)}>
                    <Tab eventKey="contactInfo" title="Contact Info">
                        <Row>
                            <Col>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <br></br>
                                    <h4>My Organisation</h4>
                                    <Row>
                                        <Col sm={9}>
                                            <Row >
                                                <Col sm={8}>
                                                    <Form.Group controlId="formOrgName">
                                                        <Form.Label>Name</Form.Label>
                                                        <FormControl type="text" name="orgName" {...register('orgName', { required: "Please enter your organisation's name." })} placeholder="e.g. company name"  />
                                                    </Form.Group>
                                                </Col>
                                                <br></br>
                                                <Col sm={8}>
                                                    <Form.Group controlId="formOrgAddress">
                                                        <Form.Label>Address</Form.Label>
                                                        <FormControl type="text" name="orgAddress" {...register('orgAddress', { required: "Please enter your organisation's address." })} placeholder="e.g. road number and name" />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Row>
                                                <Col sm={4}>
                                                    <Form.Group controlId="formOrgPostcode">
                                                        <Form.Label>PostCode</Form.Label>
                                                        <FormControl type="number" name="orgPostcode" {...register('orgPostcode', { required: "Please enter your organisation's postcode." })} placeholder="3000"  />
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={4}>
                                                    <Form.Group controlId="formOrgState">
                                                    <Form.Label>State</Form.Label>
                                                    <Form.Control as="select" name="orgState" {...register('orgState', { required: "Please enter your organisation's state." })}>
                                                        <option selected disabled>Select State</option>
                                                        <option>Australian Capital Territory</option>
                                                        <option>South Australia</option>
                                                        <option>Northern Territory</option>
                                                        <option>New South Wales</option>
                                                        <option>Western Australia</option>
                                                        <option>Tasmania</option>
                                                        <option>Victoria</option>
                                                        <option>Queensland</option>
                                                    </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Row>
                                                <Col sm={8}>
                                                    <Form.Group controlId="formOrgContact">
                                                        <Form.Label>Contact Number</Form.Label>
                                                        <FormControl type="number" {...register('orgContactNumber', { required: "Please enter your organisation's contact number." })} name="orgContactNumber" placeholder="e.g. 03 1234 1234"  />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Row>
                                                <Col sm={8}>
                                                    <Form.Group controlId="formOrgWebsite">
                                                        <Form.Label>Website</Form.Label>
                                                        <FormControl type="text" name="orgWebsite" {...register('orgWebsite')} placeholder="e.g. https://www.florabank.org.au/"  />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <button className="btn btn-primary" type="submit">Add Organisation</button>   
                                            <span>&nbsp;</span>
                                            <Link to="/userprofile"><button className="btn btn-warning">Back to User Profile</button></Link>
                                        </Col>
                                    </Row>
                                    <br></br>
                                </Form>
                            </Col>
                        </Row>
                    </Tab>

                    <Tab eventKey="organisationDetails" title="Organisation Details">
                        <Row >
                            <Col>
                                <Form onSubmit={handleSubmit(onSubmitOrgDetails)}>
                                <br></br>
                                    <h4>Organisation Seed Interests</h4>
                                    <p>(Select as many as required)</p>
                                    <Row>
                                        <Col sm={9}>

                                            <br></br>
                                            <Row >
                                                <div className="panel panel-default">
                                                    <div className="panel-body">
                                                        {getCheckBoxField(EOrgProfileSeedInterests.isSeedCollector)}
                                                        {getCheckBoxField(EOrgProfileSeedInterests.isResearchOrganisation)}
                                                        {getCheckBoxField(EOrgProfileSeedInterests.isSeedBank)}
                                                        {getCheckBoxField(EOrgProfileSeedInterests.isSeedProductionArea)}
                                                        {getCheckBoxField(EOrgProfileSeedInterests.isLandscapeRestoration)}
                                                        {getCheckBoxField(EOrgProfileSeedInterests.isOther)}
                                                        {getCheckBoxField(EOrgProfileSeedInterests.isNursery)}
                                                        {getCheckBoxField(EOrgProfileSeedInterests.isSeedTesting)}
                                                    </div>
                                                </div>
                                            </Row>
                                            <br></br>
                                            <div style={ seedInterestFlags.isSeedCollector ? {display: ''} : {display: 'none'}}>
                                                <SeedCollector register={register} />
                                            </div>
                                            <br></br>
                                            <div style={ seedInterestFlags.isSeedProductionArea ? {display: ''} : {display: 'none'}}>
                                                <SeedProductionArea register={register} />
                                            </div>      
                                            <br></br>
                                            <div style={ seedInterestFlags.isResearchOrganisation ? {display: ''} : {display: 'none'}}>
                                                <ResearchOrganisation  register={register} />
                                            </div>   
                                            <br></br>
                                            <div style={ seedInterestFlags.isNursery ? {display: ''} : {display: 'none'}}>
                                                <Nursery  register={register} />
                                            </div>       
                                            <br></br>
                                            <div style={ seedInterestFlags.isLandscapeRestoration ? {display: ''} : {display: 'none'}}>
                                                <LandscapeRestoration  register={register} />
                                            </div>     
                                            <br></br>
                                            <div style={ seedInterestFlags.isSeedBank ? {display: ''} : {display: 'none'}}>
                                                <SeedBank  register={register} />
                                            </div> 
                                            <br></br>
                                            <div style={ seedInterestFlags.isSeedTesting ? {display: ''} : {display: 'none'}}>
                                                <SeedTesting  register={register} />
                                            </div> 
                                            <br></br>
                                            <div style={ seedInterestFlags.isOther ? {display: ''} : {display: 'none'}}>
                                                <OtherService  register={register} />
                                            </div>                                  
                                            <br></br>
                                            <button className="btn btn-primary" type="submit">Update</button>   
                                            <span>&nbsp;</span>
                                            <Link to="/userprofile"><button className="btn btn-warning">Back to User Profile</button></Link>
                                            <br></br>
                                        </Col>
                                    </Row>
                                    <br></br>
                                </Form>
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </LoadingOverlay>
        </Container>
    }
    </Layout>  
  );
};

export default NewOrg;