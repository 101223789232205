import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormControl, Image, Row, Tab, Tabs } from "react-bootstrap";
import { ESeedProductionAreaDataFields } from "../../models/cmsModels";
import { getCheckBoxField } from "../../helpers/uiHelper";

const SeedProductionArea = ({register}) => {

    return (
        <Row >
        <h3>Seed Production Area</h3>
        <div className="panel panel-default">
            <h5>Specializations / Strata's</h5>
            <div className="panel-body">
                {getCheckBoxField(register, ESeedProductionAreaDataFields.seedProductionAreaCanopyTree, 'Canopy / trees')}
                {getCheckBoxField(register, ESeedProductionAreaDataFields.seedProductionAreaGroundLayer, 'Ground Layer / Graces / Herbs')}
                {getCheckBoxField(register, ESeedProductionAreaDataFields.seedProductionAreaSeedMidStory, 'Seed Mid Story/ Shrub')}
                {getCheckBoxField(register, ESeedProductionAreaDataFields.seedProductionAreaWetLand, 'Wet land species')}
                {getCheckBoxField(register, ESeedProductionAreaDataFields.seedProductionAreaOther, 'Other')}
            </div>
            <div className="row justify-content-end">
                <div className="col-4">
                    Select as many as required
                </div>
            </div>
            
            <br></br>
            <h5>Production Area(s) and Time(s)</h5>
            <p>Describe approximate production areas (distance from nearest town) and the time of year collection usually occurs.</p>

            <Form.Group controlId="formSPALocation">
                <Form.Label>Location</Form.Label>
                <FormControl type="text" name="location" placeholder="Location" {...register(ESeedProductionAreaDataFields.seedProductionAreaLocation)} />
            </Form.Group>
            <Form.Group controlId="formSPADistance">
                <Form.Label>Distance</Form.Label>
                <FormControl type="text" name="distance" placeholder="Distance (km)" {...register(ESeedProductionAreaDataFields.seedProductionAreaDistance)} />
            </Form.Group>
            <Form.Group controlId="formSPAMonths">
                <Form.Label>Months</Form.Label>
                <FormControl type="text" name="months" placeholder="Months of the year you actively produce seeds (eg. May till July)" {...register(ESeedProductionAreaDataFields.seedProductionAreaMonths)} />
            </Form.Group>
            <Col sm={9}>
                <br></br>
                <Row>
                    {getCheckBoxField(register, ESeedProductionAreaDataFields.seedProductionAreaSpecialistCleaning, 'Specialist Cleaning Equipment?')}
                    {getCheckBoxField(register, ESeedProductionAreaDataFields.seedProductionAreaSpecialistStorage, 'Specialist Storage Equipment?')}
                    <br></br>
                    <Form.Group>
                        <Form.Label>Further description of services/areas of expertise?</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="e.g. type your description here" {...register('seedProductionAreaServiceExpertiseDescription')} />
                    </Form.Group>
                    <br></br>
                </Row>
            </Col>
        </div>
    </Row>
    )
}

export default SeedProductionArea;