import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormControl, Image, Row, Tab, Tabs } from "react-bootstrap";
import { ESeedBankDataFields } from "../../models/cmsModels";
import { getCheckBoxField } from "../../helpers/uiHelper";

const SeedBank = ({register}) => {

    return (
        <Row >
            <h3>Seed Bank / Conservation Seed Bank</h3>
            <div className="panel panel-default">
                <div className="panel-body">
                <Col sm={9}>
                    <br></br>
                    <Row>
                        <Form.Label>States where services provided</Form.Label>
                        {getCheckBoxField(register, ESeedBankDataFields.seedBankNT, 'NT')}
                        {getCheckBoxField(register, ESeedBankDataFields.seedBankWA, 'WA')}
                        {getCheckBoxField(register, ESeedBankDataFields.seedBankSA, 'SA')}
                        {getCheckBoxField(register, ESeedBankDataFields.seedBankVIC, 'VIC')}
                        {getCheckBoxField(register, ESeedBankDataFields.seedBankTAS, 'TAS')}
                        {getCheckBoxField(register, ESeedBankDataFields.seedBankACT, 'ACT')}
                        {getCheckBoxField(register, ESeedBankDataFields.seedBankNSW, 'NSW')}
                        {getCheckBoxField(register, ESeedBankDataFields.seedBankQLD, 'QLD')}
                        <br></br>
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="e.g. Storage of native seeds" {...register(ESeedBankDataFields.seedBankDescription)} />
                        </Form.Group>
                        <br></br>
                    </Row>
                </Col>
                </div>
            </div>
        </Row>
    )
}

export default SeedBank;